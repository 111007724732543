import { IconHelp } from '@tabler/icons-react';
import { Box, Tooltip, useMarkovTheme } from '../../../design-system/v2';
import { useHelpAndSupportModal } from './useHelpAndSupportModal';

export const HelpAndSupport = () => {
  const theme = useMarkovTheme();
  const { open: openHelpAndSupportModal } = useHelpAndSupportModal();

  const handleOpenModal = () => {
    openHelpAndSupportModal();
  };

  return (
    <Tooltip offset={12} label="Help and support" position="bottom-start">
      <Box onClick={handleOpenModal} sx={{ cursor: 'pointer' }} pt="xs">
        <IconHelp size={24} color={theme.colors.dark[0]} />
      </Box>
    </Tooltip>
  );
};
