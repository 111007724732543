export const HELP_AND_SUPPORT_IMG_URL =
  'https://ik.imagekit.io/markovml/onboarding/Help%20and%20support/illustration-help-and-support.svg?updatedAt=1732775626658';

export enum HelpAndSupportTabs {
  HELP_CATEGORIES = 'HELP_CATEGORIES',
  PRODUCT_TOUR = 'PRODUCT_TOUR',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  CONTACT_SALES = 'CONTACT_SALES',
}

export enum HelpCategories {
  GETTING_STARTED = 'GETTING_STARTED',
  WORKFLOWS = 'WORKFLOWS',
  DATA_ANALYTICS = 'DATA_ANALYTICS',
  APP_BUILDER = 'APP_BUILDER',
}
