import { forwardRef } from 'react';
import { Box, BoxProps, Select, Text } from '../../../design-system/v2';
import { TriggerType } from './util';

interface TriggerItemProps extends BoxProps {
  label: string;
  description: string;
}

const TriggerItem = forwardRef<HTMLDivElement, TriggerItemProps>(
  ({ label, description, ...others }, ref) => (
    <Box {...others} ref={ref}>
      <Text variant="subTitle04" color="gray.7">
        {label}
      </Text>
      <Text variant="small03" color="gray.5">
        {description}
      </Text>
    </Box>
  ),
);

TriggerItem.displayName = 'TriggerItem';

interface TriggerTypeSelectProps {
  label: string;
  description: string;
  triggerOptions: { label: string; description: string; value: TriggerType }[];
  value: TriggerType;
  onChange: (value: TriggerType) => void;
}

export const TriggerTypeSelect = ({
  label,
  description,
  triggerOptions,
  value,
  onChange,
}: TriggerTypeSelectProps) => {
  const handleTriggerTypeChange = (newValue: TriggerType) => {
    onChange(newValue);
  };

  return (
    <Select
      ariaLabel="Run Type"
      label={label}
      placeholder="Pick value"
      description={
        <Text variant="small02" color="gray.7">
          {description}
        </Text>
      }
      options={triggerOptions}
      value={value}
      onChange={handleTriggerTypeChange}
      itemComponent={TriggerItem}
      dropdownPosition="bottom"
      maxDropdownHeight={400}
      w="100%"
      withinPortal
      styles={theme => ({
        item: {
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: theme.colors.blue[1],
              color: theme.colors.blue[0],
            },
          },
        },
      })}
    />
  );
};
