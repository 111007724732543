export enum TriggerType {
  PERIODIC = 'Periodic',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  EMAIL = 'Email',
}

export const scheduledTriggerOptions = [
  {
    label: 'Trigger On Email',
    description: 'Execute the workflow once we receive an email',
    value: TriggerType.EMAIL,
  },
  {
    label: 'Run at a regular interval',
    description: 'Execute the workflow repeatedly at set intervals',
    value: TriggerType.PERIODIC,
  },
  {
    label: 'Run everyday',
    description: 'Execute the workflow daily at a specified time',
    value: TriggerType.DAILY,
  },
  {
    label: 'Run weekly',
    description: 'Execute the workflow weekly at a specified time',
    value: TriggerType.WEEKLY,
  },
  {
    label: 'Run monthly',
    description: 'Execute the workflow on a monthly basis',
    value: TriggerType.MONTHLY,
  },
];
