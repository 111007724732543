import first from 'lodash/first';
import { Button } from '../../../design-system/v2';
import { WorkflowRun, WorkflowRunOperatorStatus } from '../../../generated/api';
import { useDebugRunStatusQuery } from '../../../queries/workflows/debug';
import { useGetOperatorListQuery } from '../../../queries/workflows/operators';
import { useLogsModal } from '../create/debug-panel/logs-modal/use-logs-modal';
import { findSourceNodes } from '../create/utils';
import { transformNodeToWorkflowNode } from '../nodes/utils';

interface ViewLogsContainerProps {
  run: WorkflowRun;
}

export const ViewLogsContainer = ({ run }: ViewLogsContainerProps) => {
  const {
    isLoading: operatorsLoading,
    isError: operatorsError,
    data: operators,
  } = useGetOperatorListQuery();
  const { isLoading, isError, data } = useDebugRunStatusQuery(run.workflowId, run.workflowRunId);
  const viewLogs = useLogsModal();

  if (isError || operatorsError) {
    return null;
  }

  const handleViewLogs = () => {
    if (!data?.dag || !operators) {
      return;
    }

    const firstErroredNode = data?.operatorsStatus?.find(
      operator => operator.statusDetails.status === WorkflowRunOperatorStatus.Failed,
    );

    if (firstErroredNode) {
      viewLogs(
        run.workflowId,
        run.workflowRunId,
        'logs',
        firstErroredNode.nodeId,
        firstErroredNode.statusDetails,
        first(
          findSourceNodes(
            {
              nodes: data.dag.nodes.map(node => transformNodeToWorkflowNode(node, operators)),
              edges: data.dag.edges,
            },
            firstErroredNode.nodeId,
          ),
        ),
      );
    }
  };

  return (
    <Button
      variant="subtle"
      h={20}
      onClick={handleViewLogs}
      disabled={isLoading || operatorsLoading}
    >
      See logs
    </Button>
  );
};
