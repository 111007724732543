import { useInputState } from '@mantine/hooks';
import dayjs from 'dayjs';

const useInitialDates = () => {
  const now = dayjs();
  const [startDate, setStartDate] = useInputState(now.add(10, 'minute').toDate());
  const [endDate, setEndDate] = useInputState(now.add(45, 'minute').toDate());

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};

export { useInitialDates };
