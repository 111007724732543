import { IconAdjustmentsHorizontal, IconWand } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import { useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Horizontal,
  Text,
  TextArea,
  Vertical,
  useMarkovTheme,
} from '../../../design-system/v2';
import { AppBuilder } from '../../../generated/api';
import { InputInfoTooltip } from './InputInfoTooltip';
import { useGeneratePromptModal } from './generate-prompt/useGeneratePromptModal';

const PROMPT_PLACEHOLDER = `<Specify your Persona.>
Example:
You are a helpful instructor.
---
<Provide more instructions>
Example:
You will be given the title of the video as Title and Outline of the Video as Outline. Use outlines to compose the transcript.
DO NOT explain the scene just give the script. Do not use any common AI-generated words.
[The video opens with a friendly instructor standing in a well-lit, cozy classroom environment.]
Maintain a conversational yet professional tone at or below a 10th-grade reading level.
`;

const EXAMPLE_PLACEHOLDER = `<Provide an example query>
Example: 
Generate a script using the following title and outline
Title: Laws of motion
Outline: 
1. Give an introduction about Newton & his contributions to science. 
2. Highlight Three Laws of Motion.`;

interface AppBuilderDetailsProps {
  prompt: string;
  example: string;
  onPromptChange: (prompt: string) => void;
  onExampleChange: (example: string) => void;
  appDetails: AppBuilder;
  onFormValuesChange: (prompt: string, example: string) => void;
}

export const AppBuilderDetailsForm = ({
  appDetails,
  prompt,
  example,
  onPromptChange,
  onExampleChange,
  onFormValuesChange,
}: AppBuilderDetailsProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { appId } = appDetails ?? {};
  const { open: openGeneratePromptModal } = useGeneratePromptModal();

  const debouncedOnFormValuesChange = useCallback(debounce(onFormValuesChange, 500), []);

  const initialValuesSyncDone = useRef<boolean>(false);

  useEffect(() => {
    if (appDetails && !initialValuesSyncDone.current) {
      // Since appDetails are updated whenever the prompt or example changes (using debounce),
      // when a user is typing, appDetails may get updated,
      // which can reset the textarea value to the last saved appDetails value.
      // Hence we sync it only once in the beginning

      initialValuesSyncDone.current = true;
      const { appProperties } = appDetails;
      onPromptChange(appProperties.appPrompt);
      onExampleChange(first(appProperties.examples) ?? '');
    }
  }, [appDetails]);

  const handlePromptChange = (prompt: string) => {
    onPromptChange(prompt);
    debouncedOnFormValuesChange(prompt, example);
  };

  const handleExampleChange = (example: string) => {
    onExampleChange(example);
    debouncedOnFormValuesChange(prompt, example);
  };

  const handleGeneratedPromptSelect = (response: string) => {
    handlePromptChange(response);
  };

  const handleGeneratePromptClick = () =>
    openGeneratePromptModal(appId, handleGeneratedPromptSelect);

  return (
    <Vertical spacing="xxl" h="100%" justify="stretch">
      <Horizontal
        p="lg"
        align="center"
        sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}
      >
        <IconAdjustmentsHorizontal size={32} color={theme.colors.gray[6]} />
        <Text variant="heading04" color="gray.9">
          Setup your app
        </Text>
      </Horizontal>

      {/* TODO: Add required validation */}
      <Vertical px="md" spacing={32}>
        <TextArea
          autoFocus
          minRows={10}
          maxRows={15}
          ariaLabel="Provide instruction prompt"
          labelProps={{ style: { width: '100%' } }}
          label={
            <Horizontal position="apart" pb="sm">
              <Horizontal align="center">
                <Text variant="subTitle02">Provide instruction prompt *</Text>
                <InputInfoTooltip>{PROMPT_PLACEHOLDER}</InputInfoTooltip>
              </Horizontal>
              <Button
                td="underline"
                onClick={handleGeneratePromptClick}
                leftIcon={<IconWand />}
                bg="white"
                sx={{
                  ':disabled': { background: 'transparent' },
                }}
              >
                Generate a prompt
              </Button>
            </Horizontal>
          }
          placeholder="Type instruction prompt or generate a prompt by clicking the Generate a prompt button "
          value={prompt}
          onChange={handlePromptChange}
        />
        <TextArea
          label={
            <Horizontal align="center" pb="sm">
              <Text variant="subTitle02">Add a placeholder help text</Text>
              <InputInfoTooltip>{EXAMPLE_PLACEHOLDER}</InputInfoTooltip>
            </Horizontal>
          }
          ariaLabel="Add a placeholder help text"
          placeholder="Add a placeholder help text"
          value={example}
          onChange={handleExampleChange}
          minRows={7}
          maxRows={15}
        />
      </Vertical>
    </Vertical>
  );
};
