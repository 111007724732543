import { IconActivity, IconArrowsSplit2, IconSparkles, IconStairs } from '@tabler/icons-react';
import { ScrollArea, Text, Vertical } from '../../../design-system/v2';
import { HelpCategoryCard } from './HelpCategoryCard';
import { HelpCategories } from './util';

const CATEGORIES = [
  {
    id: HelpCategories.GETTING_STARTED,
    title: 'Getting started',
    icon: <IconStairs strokeWidth="1.5px" size={24} />,
    primaryLinkText: 'Welcome to MarkovML Docs',
    primaryLinkId: 'welcome-to-markovml-docs',
  },
  {
    id: HelpCategories.WORKFLOWS,
    title: 'Workflows',
    icon: <IconArrowsSplit2 strokeWidth="1.5px" size={24} />,
    primaryLinkText: 'Introduction to AI Workflows',
    primaryLinkId: 'introduction-to-workflows',
    expandableSection: [
      {
        id: 'how-to-create-a-new-workflow',
        label: 'Create a new workflow',
      },
      {
        id: 'edit-a-workflow',
        label: 'Edit a workflow',
      },
      {
        id: 'run-a-workflow',
        label: 'Run a workflow',
      },
      {
        id: 'check-previous-test-run-details',
        label: 'Check previous run details',
      },
      {
        id: 'lock-and-unlock-workflows',
        label: 'Lock and unlock workflows',
      },
      {
        id: 'schedule-a-workflow',
        label: 'Schedule a workflow',
      },
      {
        id: 'edit-workflow-schedule-details',
        label: 'Edit workflow schedule details',
      },
      {
        id: 'pause-or-delete-schedule',
        label: 'Pause or delete scheduled runs',
      },
      {
        id: 'clone-your-workflow',
        label: 'Clone your workflow',
      },
      {
        id: 'save-as-template',
        label: 'Save as template',
      },
    ],
  },
  {
    id: HelpCategories.DATA_ANALYTICS,
    title: 'Data Analytics',
    icon: <IconActivity strokeWidth="1.5px" size={24} />,
    primaryLinkText: 'Introduction to Data Analytics',
    primaryLinkId: 'data-analytics',
    expandableSection: [
      {
        id: 'upload-file',
        label: 'Upload file',
      },
      {
        id: 'connect-to-database',
        label: 'Connect to database',
      },
      {
        id: 'query-your-data',
        label: 'Query your data',
      },
      {
        id: 'change-query-mode',
        label: 'Change query mode',
      },
      {
        id: 'preview-your-data',
        label: 'Preview your data table',
      },
      {
        id: 'chat-history',
        label: 'Chat history',
      },
      {
        id: 'create-new-chat',
        label: 'State a new chat',
      },
      {
        id: 'switch-between-charts',
        label: 'Switch between charts',
      },
      {
        id: 'download-table-data',
        label: 'Download table data',
      },
    ],
  },
  {
    id: HelpCategories.APP_BUILDER,
    title: 'App Builder',
    icon: <IconSparkles strokeWidth="1.5px" size={24} />,
    primaryLinkText: 'Introduction to App Builder',
    primaryLinkId: 'app-builder',
    expandableSection: [
      {
        id: 'create-a-new-app',
        label: 'Create a new app',
      },
      {
        id: 'generate-ai-prompt-or-instructions',
        label: 'Generate AI instruction prompts',
      },
      {
        id: 'publish-an-app',
        label: 'Publish your app',
      },
      {
        id: 'find-your-custom-app',
        label: 'Find your custom app',
      },
    ],
  },
];

export const HelpCategoriesSection = () => (
  <Vertical spacing="xl">
    <Text variant="subTitle02" color="gray.8">
      Categories
    </Text>
    <ScrollArea h={456} offsetScrollbars>
      <Vertical spacing="lg">
        {CATEGORIES.map(category => (
          <HelpCategoryCard
            key={category.id}
            title={category.title}
            icon={category.icon}
            primaryLinkText={category.primaryLinkText}
            primaryLinkId={category.primaryLinkId}
            expandableSection={category.expandableSection}
          />
        ))}
      </Vertical>
    </ScrollArea>
  </Vertical>
);
