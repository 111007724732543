import { Button, Text, notifications } from '../../../design-system/v2';
import { logger } from '../../../initializers/logging';
import { downloadFromUrl } from '../../../lib/util';
import { useDownloadWorkflowOutputMutation } from '../../../queries/workflows/debug';

interface RunResultsDownloadContainerProps {
  workflowId: string;
  runId: string;
}

export const RunResultsDownloadContainer = ({
  workflowId,
  runId,
}: RunResultsDownloadContainerProps) => {
  const { mutateAsync: downloadOutput, isLoading: isDownloading } =
    useDownloadWorkflowOutputMutation(workflowId, runId);

  const handleDownloadResults = async () => {
    try {
      const data = await downloadOutput();
      if (!data.data.downloadLink) {
        logger.warn('No file to download');
        return;
      }
      downloadFromUrl(data.data.downloadLink, data.data.fileName ?? 'results.csv');
    } catch {
      notifications.error('There was an error downloading your results. Please try again.');
    }
  };

  return (
    <Button variant="subtle" h={20} onClick={handleDownloadResults} loading={isDownloading}>
      <Text variant="subTitle04" color="blue.7">
        Download results
      </Text>
    </Button>
  );
};
