import { ArtifactStateType, ExecutionType, Operator } from '@/generated/api';

export enum FilterableFields {
  ARTIFACT_STATE = 'artifactState',
  SCHEDULE_STATUS = 'scheduleStatus',
}

export const scheduledOnlyFilter = {
  field: FilterableFields.SCHEDULE_STATUS,
  operator: Operator.In,
  value: ExecutionType.Scheduled,
};

export const lockedOnlyFilter = {
  field: FilterableFields.ARTIFACT_STATE,
  operator: Operator.In,
  value: ArtifactStateType.Active,
};
