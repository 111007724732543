import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { IconLockFilled } from '@tabler/icons-react';
import { Text, Vertical, useMarkovTheme } from '../../../../design-system/v2';
import { ArtifactStateType } from '../../../../generated/api';
import { useWorkflowStateUpdateMutation } from '../../../../queries/workflows/list/list';

export const useToggleLockWorkflowModal = (workflowId: string, stateUpdate: ArtifactStateType) => {
  const theme = useMarkovTheme();
  const { mutateAsync: updateWorkflowState } = useWorkflowStateUpdateMutation();

  const requestToLock = stateUpdate === ArtifactStateType.Active;
  const title = `Confirm ${requestToLock ? 'Locking' : 'Unlock'} workflow`;

  const content = requestToLock ? (
    <>
      <Text variant="bodyLong02" color="gray.9">
        Do you want to lock this workflow? Locking it will prevent any further changes, helping you
        avoid unintended modifications by others.
      </Text>
      <Text>You can unlock the workflow at any time if you need to make adjustments.</Text>
    </>
  ) : (
    <Text variant="bodyLong02" color="gray.9">
      Are you sure you want to unlock this workflow? Unlocking will permit you and everyone in the
      workspace to make changes.
    </Text>
  );

  const confirmLabel = requestToLock ? 'Lock workflow' : 'Unlock workflow';

  return () =>
    openConfirmModal({
      title,
      children: <Vertical>{content}</Vertical>,
      size: 'lg',
      labels: {
        confirm: <Text variant="subTitle04">{confirmLabel}</Text>,
        cancel: 'Cancel',
      },
      confirmProps: {
        leftIcon: requestToLock ? (
          <IconLockFilled color={theme.colors.yellow[7]} size={16} />
        ) : null,
      },
      onCancel: () => closeAllModals(),
      onConfirm: () => {
        updateWorkflowState({
          workflowIds: [workflowId],
          artifactState: requestToLock ? ArtifactStateType.Active : ArtifactStateType.Draft,
        });
      },
    });
};
