import {
  IconCompass,
  IconHeadset,
  IconLifebuoy,
  IconShieldLock,
  IconVocabulary,
} from '@tabler/icons-react';
import noop from 'lodash/noop';
import {
  Box,
  Horizontal,
  Image,
  Text,
  UnstyledButton,
  Vertical,
  closeAllModals,
  createStyles,
} from '../../../design-system/v2';
import { startFlow } from '../../../initializers/userflow';
import { mailToSupport } from '../../workflows/create/workflow-builder/operator-parameter-form/util';
import { HELP_AND_SUPPORT_IMG_URL, HelpAndSupportTabs } from './util';

//Right now these are dummy tabs
const TABS = [
  {
    id: HelpAndSupportTabs.HELP_CATEGORIES,
    label: 'Help categories',
    icon: <IconVocabulary />,
    onClick: noop,
  },
  {
    id: HelpAndSupportTabs.PRODUCT_TOUR,
    label: 'Product tour',
    icon: <IconCompass />,
    onClick: () => {
      startFlow();
      closeAllModals();
    },
  },
  {
    id: HelpAndSupportTabs.PRIVACY_POLICY,
    label: 'Privacy policy',
    icon: <IconShieldLock />,
    onClick: () => window.open('https://www.markovml.com/privacy-policy'),
  },
  {
    id: HelpAndSupportTabs.CONTACT_SUPPORT,
    label: 'Contact support',
    icon: <IconLifebuoy />,
    onClick: () => {
      window.location.href = mailToSupport('Contact support');
    },
  },
  {
    id: HelpAndSupportTabs.CONTACT_SALES,
    label: 'Contact sales',
    icon: <IconHeadset />,
    onClick: () => window.open('https://www.markovml.com/contact-us'),
  },
];

export const useHelpAndSupportTabsStyles = createStyles(theme => ({
  tab: {
    padding: '8px',
    borderRadius: theme.radius.sm,
    color: theme.colors.gray[8],
    svg: {
      width: '20px',
      height: '20px',
      color: theme.colors.gray[8],
    },
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  active: {
    padding: '8px',
    backgroundColor: theme.colors.blue[0],
    color: theme.colors.blue[6],
    svg: {
      width: '20px',
      height: '20px',
      color: theme.colors.blue[6],
    },
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

export const HelpAndSupportModalTabs = () => {
  const { classes, cx } = useHelpAndSupportTabsStyles();

  return (
    <Vertical spacing="lg" bg="gray.0" p="md" sx={{ borderRadius: '8px' }}>
      {TABS.map(tab => (
        <UnstyledButton
          component={Horizontal}
          noWrap
          key={tab.id}
          onClick={tab.onClick}
          className={cx(classes.tab, {
            [classes.active]: tab.id === HelpAndSupportTabs.HELP_CATEGORIES,
          })}
        >
          <Box>{tab.icon}</Box>
          <Text>{tab.label}</Text>
        </UnstyledButton>
      ))}
      <Box h={200} w={200} mt="sm">
        <Image src={HELP_AND_SUPPORT_IMG_URL} />
      </Box>
    </Vertical>
  );
};
