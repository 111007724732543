import { IconLockFilled } from '@tabler/icons-react';
import {
  Badge,
  Center,
  EllipsisText,
  Flex,
  Space,
  Text,
  useMarkovTheme,
} from '../../../../design-system/v2';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { ArtifactStateType, Workflow, WorkflowScheduleStatus } from '../../../../generated/api';
import { getScheduleBadgeColor, getScheduleStatusText } from '../../util';

export const WorkflowNameRenderer = ({ value, data }: ICellRendererParams<Workflow>) => {
  const theme = useMarkovTheme();
  const status = data?.scheduleStatus;
  const badgeColor = getScheduleBadgeColor(status);
  const displayText = getScheduleStatusText(status);

  const isLocked = data?.artifactState.state === ArtifactStateType.Active;

  return (
    <Flex w="100%" h="100%" justify="flex-start" align="center" gap={4}>
      <Center sx={{ flexShrink: 0 }}>
        {isLocked ? (
          <IconLockFilled color={theme.colors.yellow[7]} size={16} />
        ) : (
          <Space w={16} h={16} />
        )}
      </Center>
      <EllipsisText variant="subTitle04" color="gray.7" sx={{ flexGrow: 1 }}>
        {value}
      </EllipsisText>
      {status === WorkflowScheduleStatus.Scheduled ? (
        <Badge
          variant="outline"
          sx={theme => {
            const color = theme.colors[badgeColor];

            return {
              color: color[7],
              borderColor: color[3],
              backgroundColor: color[1],
              flexShrink: 0,
            };
          }}
        >
          <Text variant="small03">{displayText}</Text>
        </Badge>
      ) : null}
    </Flex>
  );
};
