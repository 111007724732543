import { IconCalendar } from '@tabler/icons-react';
import { DateTimePicker, Horizontal } from '../../../design-system/v2';

interface DateTimePickersContainerProps {
  startDate: Date;
  endDate: Date;
  handleStartDateChange: (date: Date | null) => void;
  handleEndDateChange: (date: Date | null) => void;
}

export const DateTimePickersContainer = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}: DateTimePickersContainerProps) => (
  <Horizontal w="100%" noWrap>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <DateTimePicker
      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
      valueFormat="DD MMM YYYY hh:mm A"
      label="Starting On"
      placeholder="Pick start date"
      minDate={new Date()}
      value={startDate}
      onChange={handleStartDateChange}
      w="100%"
      popoverProps={{ withinPortal: true }}
    />

    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <DateTimePicker
      icon={<IconCalendar size="1.1rem" stroke={1.5} />}
      valueFormat="DD MMM YYYY hh:mm A"
      label="Ending On"
      placeholder="Pick end date"
      value={endDate}
      onChange={handleEndDateChange}
      minDate={startDate}
      w="100%"
      popoverProps={{ withinPortal: true }}
    />
  </Horizontal>
);
