import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { globalEvents } from '../../analytics';
import { useLogout } from '../../components/app-header/account/LogoutButton';
import { PageErrorScreen } from '../../components/common/ErrorState';
import { OnboardingInfo } from '../../components/user-onboarding/user-details/util';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useAuthLogin } from '../../contexts/auth-provider/Auth';
import { LayoutContextProvider } from '../../contexts/layout/LayoutContext';
import { Box, FullPageLoader } from '../../design-system';
import { Button } from '../../design-system/v2';
import { UserType } from '../../generated/api';
import { sendAnalytics } from '../../initializers/analytics';
import { logger } from '../../initializers/logging';
import { PageLayout } from '../../layouts';
import { cleanErrorsInSearchParams } from '../../lib/router';
import { useUserInfo } from '../../queries/user';
import { AppRoutes, getRoute } from '../../router/constants';
import { RedirectionRoutes } from '../../router/redirectionConstants';
import { AuthError } from './AuthError';

export const AppContainer = () => {
  const { userId, loginError } = useAppMetadata();
  const { isLoading, data, isError, isFetching } = useUserInfo();
  const { handleLoginRedirect } = useAuthLogin();
  const handleLogout = useLogout();

  useEffect(() => {
    if (userId && data) {
      const { user } = data;
      sendAnalytics(
        globalEvents.appLoaded({
          user: { persona: user.userType },
        }),
      );
    }
  }, [userId, data]);

  if (loginError) {
    logger.error(loginError || 'Auth0 issue. User unable to login. Check Auth0 logs!');
    return <AuthError />;
  }

  if (!userId) {
    handleLoginRedirect();
    return <></>;
  }

  if (isLoading) {
    return <FullPageLoader text="Loading your details..." />;
  }

  if (isError) {
    return (
      // TODO: Discuss with design what can be shown here
      <Box mt={48} mx={24} width="100%">
        <PageErrorScreen
          iconSize="lg"
          iconName="broken_plate"
          title="Unable to load your user details"
          subtitle="Refresh page after sometime or please contact support"
          button={
            <Button variant="primary" onClick={handleLogout}>
              Try logging in again
            </Button>
          }
        />
      </Box>
    );
  }

  const onboardingInfo: OnboardingInfo = data?.user.onboardingInfo as OnboardingInfo;
  const userPainPoints = onboardingInfo.painPoints;
  const userIntent = onboardingInfo.userIntent;

  if (data?.user.userType === UserType.Undefined && !userPainPoints && !userIntent) {
    /**
     * After onboarding info is completed, userInfo gets re-fetched.
     * During this time, `data.user.userType` is still UNDEFINED and therefore this prompts the page to Onboarding page again
     * So if the data is being fetched in the background, we need to show a loader
     * But we don't need to do this at every call. This is specifically needed only when deciding whether to show
     * onboarding flow or the normal pages
     */

    const currentSearch = window.location.search;
    const params = new URLSearchParams(currentSearch);
    const cleanParams = new URLSearchParams(cleanErrorsInSearchParams(params.toString()));
    const pathname = window.location.pathname;

    Object.values(RedirectionRoutes).forEach(route => {
      if (pathname.includes(route)) {
        cleanParams.set('source', route);
      }
    });

    if (isFetching) {
      return <FullPageLoader text="Loading your details..." />;
    }

    return (
      <Navigate
        to={{
          pathname: getRoute(AppRoutes.ONBOARDING),
          search: cleanParams.toString(),
        }}
      />
    );
  }

  return (
    <LayoutContextProvider>
      <PageLayout />
    </LayoutContextProvider>
  );
};
