import noop from 'lodash/noop';
import {
  Button,
  Card,
  Flex,
  Horizontal,
  Overlay,
  Text,
  Vertical,
  useHover,
} from '../../design-system/v2';
import { UserInfoContainer } from '../model-apps/UserInfo.container';
import { UserDetail } from '../settings/UserDetail';

export const DEFAULT_BUTTON_TEXT = 'Launch App';
export const COMING_SOON_BUTTON_TEXT = 'Coming soon';
export const SEE_DEMO_BUTTON_TEXT = 'See demo';

interface SubscribedAppsCardProps {
  appId: string;
  appName: string;
  author?: string;
  userId?: string;
  description: string;
  icon: JSX.Element;
  onButtonClick?: () => void;
  buttonText?: string;
}

export const SubscribedAppsCard = ({
  appId,
  appName,
  author,
  userId,
  description,
  icon,
  onButtonClick,
  buttonText = DEFAULT_BUTTON_TEXT,
}: SubscribedAppsCardProps): JSX.Element => {
  const { hovered, ref } = useHover();
  const isBtnDisabled = buttonText === COMING_SOON_BUTTON_TEXT;

  return (
    <Card
      data-testid={`app-store-${appId}`}
      radius="lg"
      shadow="sm"
      withBorder
      onClick={isBtnDisabled ? noop : onButtonClick}
      pos="relative"
      ref={ref}
    >
      <Card.Section
        bg="linear-gradient(180deg, #E9EFFF 0%, #FFF 100%)"
        component={Flex}
        align="center"
        justify="center"
        h={167}
      >
        {icon}
      </Card.Section>
      {hovered && !isBtnDisabled && (
        <Overlay
          color="transparent"
          opacity={0}
          component={Horizontal}
          position="right"
          align="end"
          p="md"
        >
          <Button
            variant="primary"
            bg="dark.6"
            shape="rounded"
            sx={theme => ({
              '&:hover': {
                backgroundColor: theme.colors.dark[6],
              },
            })}
          >
            {buttonText}
          </Button>
        </Overlay>
      )}
      <Vertical spacing="sm">
        <Text variant="subTitle02" color="gray.9">
          {appName}
        </Text>
        <Text h={108} variant="subTitle05" color="gray.6" lineClamp={4}>
          {description}
        </Text>
        <Horizontal position="apart">
          {author && <UserDetail name="" email={author} avatarSize="sm" />}
          {userId && <UserInfoContainer userId={userId} />}

          {isBtnDisabled && (
            <Button
              disabled={isBtnDisabled}
              variant="primary"
              bg="dark.6"
              shape="rounded"
              sx={theme => ({
                '&:hover': {
                  backgroundColor: theme.colors.dark[6],
                },
              })}
            >
              {buttonText}
            </Button>
          )}
        </Horizontal>
      </Vertical>
    </Card>
  );
};
