import { closeAllModals, openConfirmModal } from '@mantine/modals';
import toLower from 'lodash/toLower';
import { useNavigate } from 'react-router-dom';
import { Text, notifications } from '../../../../design-system/v2';
import { logger } from '../../../../initializers/logging';
import { toPlural } from '../../../../lib/ui';
import { useWorkflowDeleteMutation } from '../../../../queries/workflows/list/list';
import { useAbsoluteRoutes } from '../../../../router/hooks';

export const useWorkflowDelete = (workflowIds: string[], resetWorkflowSelection?: () => void) => {
  const count = workflowIds.length;
  const displayName = count === 1 ? 'workflow' : toPlural(count, 'workflow');
  const displayState = 'Delete';
  const headingText = `${displayState} ${displayName}`;
  const subheading = `Are you sure you would like to permanently ${toLower(
    displayState,
  )} the selected ${displayName}?`;

  const navigate = useNavigate();
  const { getWorkflowsListRoute } = useAbsoluteRoutes();

  const handleSelectionReset = () => {
    resetWorkflowSelection?.();
  };

  const { mutateAsync: deleteWorkflow } = useWorkflowDeleteMutation();

  const onConfirmDeletion = async () => {
    try {
      await deleteWorkflow({ workflowIds });
      handleSelectionReset();
      notifications.success(`${displayState}d selected ${displayName}`);
      navigate(getWorkflowsListRoute());
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };

  const openDeleteModal = () =>
    openConfirmModal({
      title: <Text variant="heading03">{headingText}</Text>,
      size: 'lg',
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => closeAllModals(),
      onConfirm: () => onConfirmDeletion(),
      children: <Text variant="bodyShort01">{subheading}</Text>,
    });

  return { openDeleteModal };
};
