import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ArtifactStateType,
  Workflow,
  WorkflowScheduleStatus,
  WorkflowStatus,
} from '../../../../../generated/api';
import { useToggleLockWorkflowModal } from '../../../actions/lock-unlock/use-toggle-lock';
import { useWorkflowClone } from '../../../list/workflow-actions/useCloneWorkflow';
import { useWorkflowAsJsonExport } from '../../../list/workflow-actions/useExportJsonTemplate';
import { useWorkflowExport } from '../../../list/workflow-actions/useExportWorkflow';
import { useUpdateScheduleStatusModal } from '../../../list/workflow-actions/useUpdateScheduleStatusModal';
import { useWorkflowArchiveUnarchive } from '../../../list/workflow-actions/useWorkflowArchiveUnarchive';
import { useWorkflowDelete } from '../../../list/workflow-actions/useWorkflowDelete';
import { useScheduleModal } from '../../../scheduled-runs/modal/use-schedule';

interface WorkflowAction {
  id: string;
  label: string;
  handler: () => void;
  visible: boolean;
}

export const useWorkflowActions = (workflow?: Workflow): WorkflowAction[] => {
  const { featureDevTemplateExport } = useFlags();

  const workflowId = workflow?.workflowId ?? '';
  const state = (workflow?.artifactState.state ?? ArtifactStateType.Draft) as ArtifactStateType;
  const scheduleStatus = workflow?.scheduleStatus ?? WorkflowScheduleStatus.Scheduled;

  const confirmToggleLockWorkflow = useToggleLockWorkflowModal(
    workflowId,
    state === ArtifactStateType.Active ? ArtifactStateType.Draft : ArtifactStateType.Active,
  );
  const openScheduleModal = useScheduleModal(workflowId);
  const { open: openUpdateScheduleStatus } = useUpdateScheduleStatusModal(
    workflowId,
    workflow?.status ?? WorkflowStatus.Empty,
  );
  const { openArchiveUnarchiveModal } = useWorkflowArchiveUnarchive([workflowId], state);
  const { openDeleteModal } = useWorkflowDelete([workflowId]);
  const { openCloneModal } = useWorkflowClone(workflowId);
  const { openExportModal } = useWorkflowExport(workflowId);
  const { openExportWorkflowAsJsonModal } = useWorkflowAsJsonExport(workflowId);

  const archiveAction = {
    id: 'archive-workflow',
    label: state === ArtifactStateType.Archived ? 'Unarchive' : 'Archive',
    handler: openArchiveUnarchiveModal,
    visible: true,
  };

  if (state === ArtifactStateType.Archived) {
    return [archiveAction];
  }

  const isScheduled = scheduleStatus === WorkflowScheduleStatus.Scheduled;
  const toggleLockAction = {
    id: 'toggle-lock-workflow',
    label: state === ArtifactStateType.Active ? 'Unlock' : 'Lock',
    handler: confirmToggleLockWorkflow,
    visible: !isScheduled,
  };

  const scheduleAction = {
    id: 'schedule-workflow',
    label: 'Schedule',
    handler: openScheduleModal,
    visible: !isScheduled,
  };
  const removeScheduleAction = {
    id: 'remove-schedule-workflow',
    label: 'Remove schedule',
    handler: openUpdateScheduleStatus,
    visible: isScheduled,
  };
  const deleteAction = {
    id: 'delete-workflow',
    label: 'Delete',
    handler: openDeleteModal,
    visible: true,
  };
  const cloneAction = {
    id: 'clone-workflow',
    label: 'Clone',
    handler: openCloneModal,
    visible: true,
  };
  const exportAsTemplateAction = {
    id: 'export-workflow',
    label: 'Save As Template',
    handler: openExportModal,
    visible: true,
  };
  const exportAsJsonTemplateAction = {
    id: 'export-json-workflow',
    label: 'Devs Only Export',
    handler: openExportWorkflowAsJsonModal,
    visible: featureDevTemplateExport,
  };

  return [
    toggleLockAction,
    scheduleAction,
    removeScheduleAction,
    cloneAction,
    archiveAction,
    deleteAction,
    exportAsTemplateAction,
    exportAsJsonTemplateAction,
  ].filter(action => action.visible);
};
