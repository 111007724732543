import { TimeInput } from '@mantine/dates';
import { IconClock } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Horizontal, useInputState } from '../../../design-system/v2';
import { getCronString } from '../../../lib/cron-lib';
import { convertToUTC } from '../../../lib/time-format';

interface DailyRunProps {
  setFrequency: (value: string) => void;
  defaultRunTime?: string;
}

export const DailyRun = ({ setFrequency, defaultRunTime = '' }: DailyRunProps) => {
  const [runTime, setRunTime] = useInputState(defaultRunTime);

  useEffect(() => {
    if (runTime) {
      const { hh, mm } = convertToUTC(runTime);
      const cronString = getCronString('day', [], [], [], [hh], [mm], false, {});

      setFrequency(cronString);
    }
  }, [runTime, setFrequency]);

  return (
    <Horizontal w="100%">
      <TimeInput
        icon={<IconClock size="1rem" stroke={1.5} />}
        label="Run daily at"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          setRunTime(value);
        }}
        value={runTime}
        required
      />
    </Horizontal>
  );
};
