import { IconArrowRight } from '@tabler/icons-react';
import {
  Box,
  Button,
  Image,
  Space,
  Text,
  Vertical,
  closeAllModals,
  openModal,
} from '../../design-system/v2';
import { WORKFLOW_BUILDER_FLOW, startFlow } from '../../initializers/userflow';
import { useCreateBlankWorkflow } from '../workspace/studio-home/workflow/use-add-workflow';

const MKV_COMMUNITY_SLACK_WORKSPACE_URL =
  'https://join.slack.com/t/markovmlcommunity/shared_invite/zt-2tras9ume-EjL8r2hEVFLJqC~yOHuGLA';

const BUTTON_WIDTH = 300;

const FreeTrialModal = (): JSX.Element => {
  const { createBlankWorkflow } = useCreateBlankWorkflow();

  const handleNavigateToSlackCommunity = () => {
    window.open(MKV_COMMUNITY_SLACK_WORKSPACE_URL, '_blank');
  };

  const handleStartTour = () => {
    createBlankWorkflow();
    startFlow(WORKFLOW_BUILDER_FLOW);
    closeAllModals();
  };

  return (
    <Vertical py={16}>
      <Box h={160} w={160} m="auto" bg="blue.1" p="sm" mb="md" sx={{ borderRadius: '50%' }}>
        <Image src="https://ik.imagekit.io/markovml/homepage/free_trial_31XVeYP2N.svg?updatedAt=1728881238155" />
      </Box>
      <Vertical spacing="sm" px={36} ta="center">
        <Text variant="heading02">Welcome to MarkovML</Text>
        <Text variant="bodyLong01" mx="xxl">
          We are pleased to offer you a{' '}
          <Text span variant="bodyLong01" fw={600} color="blue.6">
            30 day free trial
          </Text>{' '}
          of our Team plan as a thank you for being an early adopter of MarkovML.
        </Text>
      </Vertical>
      <Space h={24} />
      <Vertical align="center">
        <Button
          variant="primary"
          size="lg"
          w={BUTTON_WIDTH}
          onClick={handleStartTour}
          rightIcon={
            // Padding to improve alignment
            <Box pl="xs" pt="xs">
              <IconArrowRight />
            </Box>
          }
        >
          {`Let's build a Workflow`}
        </Button>
        {/* Removing this for now as per feedback, might get added later on
        <Button
          variant="subtle"
          size="lg"
          w={BUTTON_WIDTH}
          onClick={handleNavigateToSlackCommunity}
          leftIcon={
            <Box mr="xs">
              <SlackLogo width={20} />
            </Box>
          }
        >
          Join Slack Community
        </Button> */}
      </Vertical>
    </Vertical>
  );
};

export const useFreeTrialModal = () => {
  const open = () =>
    openModal({
      size: 540,
      radius: 'sm',
      children: <FreeTrialModal />,
    });

  return { open };
};
