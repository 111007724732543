import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import { ScrollArea, VerticalList, createStyles } from '../../../../design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { WorkflowRunListItem } from './WorkflowRunListItem';
import { WorkflowRunTabs } from './WorkflowRunsListTabs';

const useListItemStyles = createStyles(theme => ({
  listItem: {
    borderRadius: '8px',
    '.mantine-List-itemWrapper': {
      width: '100%',
    },
    cursor: 'pointer',
    ':hover': {
      background: theme.colors.gray[1],
    },
  },
  selected: {
    background: theme.colors.blue[1],
    ':hover': {
      background: theme.colors.blue[1],
    },
  },
}));

export interface WorkflowRunsListProps {
  workflowRuns: Array<WorkflowRun>;

  onWorkflowRunDetailsClick: (id: string) => void;
  activeTab: WorkflowRunTabs;
}

export const WorkflowRunsList = ({
  workflowRuns,
  onWorkflowRunDetailsClick,
  activeTab,
}: WorkflowRunsListProps) => {
  const { runId } = useWorkflowRunContext();
  const { classes, cx } = useListItemStyles();

  return (
    <ScrollArea h="100%">
      <VerticalList spacing="xsm" px="md" py="xxl" listStyleType="none" sx={{ flexGrow: 1 }}>
        {workflowRuns.map(run => {
          const { workflowRunId } = run;
          const isSelected = workflowRunId === runId;

          const listItemClass = cx(classes.listItem, {
            [classes.selected]: isSelected,
          });

          return (
            <VerticalList.Item
              key={workflowRunId}
              mb="lg"
              onClick={() => onWorkflowRunDetailsClick(workflowRunId)}
              className={listItemClass}
            >
              <WorkflowRunListItem
                run={run}
                isUpcoming={activeTab === WorkflowRunTabs.UPCOMING}
                isSelected={isSelected}
              />
            </VerticalList.Item>
          );
        })}
      </VerticalList>
    </ScrollArea>
  );
};
