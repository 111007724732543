import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback } from 'react';
import { PaymentSubscriptionType } from '../../../generated/api';
import { useCreateCheckoutSessionMutation } from '../../../queries/account/payment-and-subscription';

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY as string;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface CheckoutFormProps {
  subscriptionType: PaymentSubscriptionType;
  onComplete?: () => void;
}

export const SubscriptionPlanCheckoutForm = ({
  subscriptionType,
  onComplete,
}: CheckoutFormProps) => {
  const { mutateAsync: createCheckoutSession } = useCreateCheckoutSessionMutation();

  const fetchClientSecret = useCallback(async () => {
    const res = await createCheckoutSession(subscriptionType);
    return res.data.clientSecret;
  }, [subscriptionType]);

  const options = {
    fetchClientSecret,
    onComplete,
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
