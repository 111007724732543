import { IconArrowRight } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  LinkButton,
  ScrollArea,
  Tabs,
  Text,
  Vertical,
  useIntersection,
} from '../../../../design-system/v2';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { EdTechAppsContainer } from '../../../app-store/EdTechApps.container';
import { Header } from '../layout/Header';
import { DataInsightsSection } from '../sections/DataInsightsSection';
import { WorkflowsSectionContainer } from '../sections/WorkflowsSection.container';
import { HomeSection } from './HomeSection';

enum GlobalHomepageTabs {
  ANALYZE_DATA = 'ANALYZE_DATA',
  WORKFLOWS = 'WORKFLOWS',
  APP_STORE = 'APP_STORE',
}

const orderedTabs = [
  GlobalHomepageTabs.WORKFLOWS,
  GlobalHomepageTabs.APP_STORE,
  GlobalHomepageTabs.ANALYZE_DATA,
];

const tabDetails = {
  [GlobalHomepageTabs.WORKFLOWS]: {
    label: 'Workflows',
    description: 'Define and run multistage processes on your data',
  },
  [GlobalHomepageTabs.APP_STORE]: {
    label: 'Explore the App Store',
    description: 'Discover and share GenAI apps',
  },
  [GlobalHomepageTabs.ANALYZE_DATA]: {
    label: 'Analyze your data',
    description: 'Gain valuable insights about your data quickly',
  },
};

export const GlobalHome = () => {
  const { featureWorkflows, featureAppStore } = useFlags();
  const { getWorkflowsHomeRoute, getAppStoreRoute, getDataStudioHomeRoute } = useAbsoluteRoutes();
  const [activeTab, setActiveTab] = useState<GlobalHomepageTabs>(GlobalHomepageTabs.ANALYZE_DATA);
  const viewportRef = useRef<HTMLDivElement>(null);
  const dataStudioRef = useRef<HTMLDivElement>(null);
  const workflowsRef = useRef<HTMLDivElement>(null);
  const appStoreRef = useRef<HTMLDivElement>(null);

  const { ref: dataStudioHeaderRef, entry: datastudioEntry } = useIntersection<HTMLDivElement>({
    root: viewportRef.current,
    threshold: 1,
  });
  const { ref: workflowsHeaderRef, entry: workflowsEntry } = useIntersection<HTMLDivElement>({
    root: viewportRef.current,
    threshold: 1,
  });
  const { ref: appStoreHeaderRef, entry: appStoreEntry } = useIntersection<HTMLDivElement>({
    root: viewportRef.current,
    threshold: 1,
  });

  useEffect(() => {
    if (datastudioEntry?.isIntersecting) {
      setActiveTab(GlobalHomepageTabs.ANALYZE_DATA);
      return;
    }

    if (workflowsEntry?.isIntersecting) {
      setActiveTab(GlobalHomepageTabs.WORKFLOWS);
      return;
    }

    if (appStoreEntry?.isIntersecting) {
      setActiveTab(GlobalHomepageTabs.APP_STORE);
      return;
    }
  }, [datastudioEntry, workflowsEntry, appStoreEntry]);

  const handleTabChange = (val: GlobalHomepageTabs) => {
    setActiveTab(val);

    if (featureWorkflows && val === GlobalHomepageTabs.WORKFLOWS) {
      viewportRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (val === GlobalHomepageTabs.ANALYZE_DATA) {
      viewportRef.current?.scrollTo({ top: dataStudioRef.current?.offsetTop, behavior: 'smooth' });
      return;
    }

    if (featureAppStore && val === GlobalHomepageTabs.APP_STORE) {
      viewportRef.current?.scrollTo({ top: appStoreRef.current?.offsetTop, behavior: 'smooth' });
      return;
    }
  };

  return (
    <Box w="100%" h="100%" bg="white.0">
      <Header />
      <Container size="xl" h="calc(100% - 120px)" mt={32} px={0}>
        <Tabs
          keepMounted
          orientation="vertical"
          value={activeTab}
          onTabChange={handleTabChange}
          unstyled
          h="100%"
          styles={theme => ({
            tabsList: {
              border: 'none',
            },
            tab: {
              ...theme.fn.focusStyles(),
              border: 'none',
              borderRadius: '8px',
              textAlign: 'left',
              background: 'transparent',
              cursor: 'pointer',
              '&[data-active]': {
                background: theme.colors.blue[0],
              },
            },
          })}
        >
          <Grid gutter={20} h="100%" m="0">
            <Grid.Col span={4}>
              <Tabs.List>
                <Vertical spacing="sm">
                  {orderedTabs.map(tab => {
                    if (
                      (tab === GlobalHomepageTabs.WORKFLOWS && !featureWorkflows) ||
                      (tab === GlobalHomepageTabs.APP_STORE && !featureAppStore)
                    ) {
                      return;
                    }
                    const tabDetail = tabDetails[tab];
                    const labelColor = tab === activeTab ? 'blue.6' : 'gray.9';
                    return (
                      <Tabs.Tab key={tab} value={tab}>
                        <Vertical spacing="xs">
                          <Text variant="subTitle02" color={labelColor}>
                            {tabDetail.label}
                          </Text>
                          <Text variant="bodyShort03" color="gray.6">
                            {tabDetail.description}
                          </Text>
                        </Vertical>
                      </Tabs.Tab>
                    );
                  })}
                </Vertical>
              </Tabs.List>
            </Grid.Col>
            <Grid.Col span={16} h="100%" px="0">
              <ScrollArea h="100%" px="24px" viewportRef={viewportRef}>
                <Vertical spacing="64px">
                  {featureWorkflows ? (
                    <HomeSection
                      ref={workflowsRef}
                      title={tabDetails[GlobalHomepageTabs.WORKFLOWS].label}
                      description={tabDetails[GlobalHomepageTabs.WORKFLOWS].description}
                      action={
                        <LinkButton to={getWorkflowsHomeRoute()} rightIcon={<IconArrowRight />}>
                          Workflows
                        </LinkButton>
                      }
                      headerRef={workflowsHeaderRef}
                    >
                      <WorkflowsSectionContainer />
                    </HomeSection>
                  ) : null}
                  {featureAppStore ? (
                    <HomeSection
                      ref={appStoreRef}
                      title={tabDetails[GlobalHomepageTabs.APP_STORE].label}
                      description={tabDetails[GlobalHomepageTabs.APP_STORE].description}
                      action={
                        <LinkButton to={getAppStoreRoute()} rightIcon={<IconArrowRight />}>
                          App store
                        </LinkButton>
                      }
                      headerRef={appStoreHeaderRef}
                    >
                      <EdTechAppsContainer
                        cols={4}
                        breakpoints={[
                          { maxWidth: 'md', cols: 3 },
                          { maxWidth: 'sm', cols: 2 },
                          { maxWidth: 'xs', cols: 1 },
                        ]}
                      />
                    </HomeSection>
                  ) : null}
                  <HomeSection
                    ref={dataStudioRef}
                    title={tabDetails[GlobalHomepageTabs.ANALYZE_DATA].label}
                    description={tabDetails[GlobalHomepageTabs.ANALYZE_DATA].description}
                    action={
                      <LinkButton to={getDataStudioHomeRoute()} rightIcon={<IconArrowRight />}>
                        Go to Data Studio
                      </LinkButton>
                    }
                    headerRef={dataStudioHeaderRef}
                  >
                    <DataInsightsSection />
                  </HomeSection>
                </Vertical>
              </ScrollArea>
            </Grid.Col>
          </Grid>
        </Tabs>
      </Container>
    </Box>
  );
};
