import { DatePickerInput, TimeInput } from '@mantine/dates';
import { IconCalendar, IconClock } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Horizontal, createStyles, useInputState } from '../../../design-system/v2';
import { getCronString } from '../../../lib/cron-lib';
import { convertToUTC } from '../../../lib/time-format';

const useCalenderHeaderStyle = createStyles(theme => ({
  calendarHeader: {
    display: 'none',
  },
  day: {
    '&[data-weekend]': {
      color: theme.colors.gray[8],
    },
    '&[data-selected]': {
      color: theme.colors.gray[0],
      backgroundColor: theme.colors.blue[6],
    },
  },
}));

interface MonthlyRunProps {
  setFrequency: (value: string) => void;
  defaultDates?: Date[];
  defaultRunTime?: string;
}

export const MonthlyRun = ({
  setFrequency,
  defaultDates = [],
  defaultRunTime = '',
}: MonthlyRunProps) => {
  const [selectedDates, setSelectedDates] = useInputState<Date[]>(defaultDates);
  const [runTime, setRunTime] = useInputState(defaultRunTime);

  const { classes } = useCalenderHeaderStyle();

  useEffect(() => {
    if (runTime) {
      const { hh, mm } = convertToUTC(runTime);
      const monthDays = selectedDates.map(date => date.getDate());

      const cronString = getCronString('month', [], monthDays, [], [hh], [mm], false, {});
      setFrequency(cronString);
    }
  }, [selectedDates, runTime, setFrequency]);

  return (
    <Horizontal mt="sm">
      <DatePickerInput
        type="multiple"
        label="Select dates of the month"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        placeholder="Pick dates range"
        valueFormat="DD"
        value={selectedDates}
        onChange={setSelectedDates}
        hideOutsideDates
        hideWeekdays
        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
        popoverProps={{ withinPortal: true }}
        previousIcon={<></>}
        monthLabelFormat={() => undefined}
        maxLevel="month"
        defaultDate={new Date(2024, 0)}
        classNames={{ calendarHeader: classes.calendarHeader, day: classes.day }}
        w="50%"
        clearable
      />
      <TimeInput
        icon={<IconClock size="1rem" stroke={1.5} />}
        label="Run At"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          setRunTime(value);
        }}
        value={runTime}
        size="sm"
        required
      />
    </Horizontal>
  );
};
