import { Box, Divider, Horizontal, Vertical, useMarkovTheme } from '@/design-system/v2';
import { WorkflowRun } from '@/generated/api';
import { useDebugRunStatusQuery } from '@/queries/workflows/debug';
import { useGetWorkflowRunCreditUsageQuery } from '@/queries/workflows/runs/runs';
import { WorkflowRunDetailsOverview } from './WorkflowRunDetailsOverview';
import {
  WorkflowCreditInformation,
  WorkflowRunDuration,
  getRunArtifact,
  getWorkflowRunIcon,
} from './util';

interface WorkflowListItemProps {
  run: WorkflowRun;
  isUpcoming?: boolean;
  isSelected?: boolean;
}

export const WorkflowRunListItem = ({
  run,
  isUpcoming = false,
  isSelected = false,
}: WorkflowListItemProps) => {
  const theme = useMarkovTheme();

  const { data: creditDetails } = useGetWorkflowRunCreditUsageQuery(
    run.workflowId,
    run.workflowRunId,
    run.status,
  );
  const { data: runStatus } = useDebugRunStatusQuery(run.workflowId, run.workflowRunId);

  const { status } = run;
  const icon = getWorkflowRunIcon(runStatus?.wflRunStatus ?? status, theme);

  return (
    <Vertical
      px="md"
      py="lg"
      sx={theme => ({
        border: isSelected
          ? `1px solid ${theme.colors.blue[2]}`
          : `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Horizontal w="100%" align="start" spacing="sm" noWrap>
        <Box pt="xs">{icon}</Box>
        <WorkflowRunDetailsOverview
          run={run}
          withExecutionTypeLabel={!isUpcoming}
          activeFlowBadge
        />
      </Horizontal>
      <Divider size={1} color={isSelected ? theme.colors.blue[2] : theme.colors.gray[2]} />
      <Horizontal position="apart" noWrap>
        <Horizontal noWrap spacing="sm">
          <WorkflowCreditInformation credits={creditDetails?.totalCreditsConsumed} />
          <WorkflowRunDuration duration={run.totalDuration ?? '--'} />
        </Horizontal>
        {getRunArtifact(run, runStatus?.wflRunStatus)}
      </Horizontal>
    </Vertical>
  );
};
