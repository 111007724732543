import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { WorkflowRunsWrapper } from '../../components/workflows/detail/runs/WorkflowRuns.wrapper';
import { UnavailablePage } from '../../pages/Unavailable';
import { renderAuthRoute } from '../AuthRouter';
import { AppRoutes, getRoute } from '../constants';
import { lazyWithRetry } from '../util';

const Detail = lazyWithRetry(() => import('../../pages/Workflows/WorkflowDetail'));
const WorkflowCreate = lazyWithRetry(() => import('../../pages/Workflows/WorkflowCreate'));
const RunDetails = lazyWithRetry(() => import('../../pages/Workflows/RunDetails'));

const workflowBuilder = getRoute(AppRoutes.WORKFLOW_BUILDER);
const workflowRuns = getRoute(AppRoutes.WORKFLOW_RUNS);
const runDetail = getRoute(AppRoutes.WORKFLOW_RUN_DETAIL);
const workflowRunDetails = `${workflowRuns}/${runDetail}`;

const detailRoutes: RouteProps[] = [
  {
    path: '/',
    element: <WorkflowCreate />,
  },
  {
    path: workflowBuilder,
    element: <WorkflowCreate />,
  },
  {
    path: workflowRuns,
    element: <WorkflowRunsWrapper />,
  },
  {
    path: workflowRunDetails,
    element: <RunDetails />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const DetailRouter = (): JSX.Element => (
  <Routes>
    <Route element={<Detail />}>{detailRoutes.map(renderAuthRoute)}</Route>
  </Routes>
);
