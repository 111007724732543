import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Navigate, useParams } from 'react-router-dom';
import { ErrorBar } from '../../components/common/ErrorBar';
import { InfoMessage } from '../../components/common/InfoMessage';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { Box } from '../../design-system';
import {
  FullPageLoader,
  LoadingWrapper,
  PageWrapper,
  SpinnerWrapper,
} from '../../design-system/Loader/FullPageLoader';
import { Center, Loader, Text, Vertical } from '../../design-system/v2';
import { AccountOwnerType, MarkovArtifactMigrationStatus } from '../../generated/api';
import { hideFreshChat, initFreshChat } from '../../initializers/freshworks';
import { isProduction } from '../../lib/env.util';
import { cleanErrorsInSearchParams } from '../../lib/router';
import {
  useGetWorkspaceSettingsDetailsQuery,
  useWorkspaceSetupQuery,
} from '../../queries/account/workspace';
import { useWorkspaceList } from '../../queries/userHome';
import { AppRoutes, getRoute } from '../../router/constants';
import { WorkspaceShell } from './WorkspaceShell';

export const WorkspaceContainerPage = (): JSX.Element => {
  const { setWorkspaceId, areFlagsLoaded } = useAppMetadata();
  const { workspaceId } = useParams();

  const { isLoading, data, error } = useWorkspaceList();
  const { isInitialLoading: isCheckingForSetup, data: isWorkspaceSetup } = useWorkspaceSetupQuery();
  const { data: workspaceSettings } = useGetWorkspaceSettingsDetailsQuery();

  const isValidWorkspace = Boolean(
    workspaceId && data?.some(workspace => workspace.workspaceId === workspaceId),
  );

  const accountType = workspaceSettings?.account.accountType;

  useEffect(() => {
    if (accountType && isProduction()) {
      if (accountType === AccountOwnerType.Enterprise) {
        hideFreshChat();
      } else {
        initFreshChat();
      }
    }
  }, [accountType]);

  useEffect(() => {
    if (isValidWorkspace) {
      setWorkspaceId(workspaceId ?? '');
    }
  }, [workspaceId, setWorkspaceId, isValidWorkspace]);

  if (!workspaceId) {
    return (
      <Navigate
        to={{
          pathname: getRoute(AppRoutes.BASE_ROUTE),
          search: cleanErrorsInSearchParams(window.location.search),
        }}
      />
    );
  }

  if (!areFlagsLoaded) return <FullPageLoader text="Checking permissions" />;

  if (isLoading || isCheckingForSetup) {
    return createPortal(
      <PageWrapper>
        <LoadingWrapper>
          <SpinnerWrapper>
            <Center h="100%" w="100%">
              <Vertical align="center" spacing="xl">
                <Loader variant="bars" />
                <Text variant="subTitle01" color="gray.9">
                  Fetching your workspace details
                </Text>
              </Vertical>
            </Center>
          </SpinnerWrapper>
        </LoadingWrapper>
      </PageWrapper>,
      document.getElementById('loader-root') as HTMLElement,
    );
  }

  if (error) {
    return (
      <Box mt={48} mx={24} width="100%">
        <ErrorBar errorMessage="Unable to load your workspace details. Try pressing the Home button or refresh the page." />
      </Box>
    );
  }

  if (!data || !data.length) {
    return (
      <Box mt={48} mx={24} width="100%">
        <InfoMessage text="Looks like you aren't part of any workspace. Please ask existing users to invite to their workspace or contact Support." />
      </Box>
    );
  }

  if (!isValidWorkspace) {
    return <Navigate to={getRoute(AppRoutes.ACCESS_DENIED)} />;
  }

  if (isWorkspaceSetup === MarkovArtifactMigrationStatus.Loading) {
    return createPortal(
      <PageWrapper>
        <LoadingWrapper>
          <SpinnerWrapper>
            <Vertical align="center" spacing="xs">
              <Loader variant="bars" mb="lg" />
              <Text variant="subTitle01" color="gray.9">
                Setting up your workspace
              </Text>
              <Text variant="bodyShort01" color="gray.9">
                Let us customize your experience
              </Text>
            </Vertical>
          </SpinnerWrapper>
        </LoadingWrapper>
      </PageWrapper>,
      document.getElementById('loader-root') as HTMLElement,
    );
  }

  return <WorkspaceShell />;
};
