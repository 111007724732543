import { forwardRef } from 'react';
import { Button, Image, Text, useMarkovTheme } from '../../../design-system/v2';
import {
  GetWorkspaceCurrentSubscriptionDetailResponseModel,
  PaymentSubscriptionStatusType,
} from '../../../generated/api';
import {
  PLAN_ICON_WIDTH,
  calculateTrialDaysRemaining,
  subscriptionPlanDetails,
} from '../../subscriptions/util';

interface SubscriptionProps {
  isLoading?: boolean;
  subscriptionDetail?: GetWorkspaceCurrentSubscriptionDetailResponseModel;
  onClick?: () => void;
}

export const getSubscriptionButtonText = (
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel,
): string => {
  const { status, currentPeriodEnd } = subscriptionDetail;

  if (status === PaymentSubscriptionStatusType.Trialing) {
    const trialDaysRemaining = calculateTrialDaysRemaining(currentPeriodEnd);
    return `Trial ends in ${trialDaysRemaining} days`;
  }

  return subscriptionPlanDetails[subscriptionDetail.subscriptionType].planName;
};

export const getSubscriptionIconUrl = (
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel,
): string => {
  const { subscriptionType } = subscriptionDetail;

  return subscriptionPlanDetails[subscriptionType].iconUrl;
};

export const SubscriptionButton = forwardRef<HTMLButtonElement, SubscriptionProps>(
  ({ isLoading, subscriptionDetail, onClick }, ref) => {
    const theme = useMarkovTheme();
    const buttonText = subscriptionDetail ? getSubscriptionButtonText(subscriptionDetail) : '';
    const iconUrl = subscriptionDetail ? getSubscriptionIconUrl(subscriptionDetail) : '';

    if (isLoading || !subscriptionDetail) {
      return null;
    }

    return (
      <Button
        ref={ref}
        onClick={onClick}
        variant="subtle"
        sx={theme => ({
          backgroundColor: theme.colors.gray[8],
          '&:hover': {
            backgroundColor: theme.colors.gray[7],
          },
        })}
        leftIcon={<Image src={iconUrl} width={PLAN_ICON_WIDTH} />}
        styles={{
          leftIcon: {
            paddingTop: 4,
          },
        }}
      >
        <Text variant="subTitle03" color={theme.colors.yellow[4]} pl="xs">
          {buttonText}
        </Text>
      </Button>
    );
  },
);

SubscriptionButton.displayName = 'SubscriptionButton';
