import { Group } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IconClock } from '@tabler/icons-react';
import { useEffect } from 'react';
import { Chip, Horizontal, Vertical, useInputState } from '../../../design-system/v2';
import { getCronString } from '../../../lib/cron-lib';
import { convertToUTC } from '../../../lib/time-format';

const daysOfWeek = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
];

interface WeeklyRunProps {
  setFrequency: (value: string) => void;
  defaultDays?: string[];
  defaultRunTime?: string;
}

export const WeeklyRun = ({
  setFrequency,
  defaultDays = ['1', '3'],
  defaultRunTime = '',
}: WeeklyRunProps) => {
  const [selectedDays, setSelectedDays] = useInputState<string[]>(defaultDays);
  const [runTime, setRunTime] = useInputState(defaultRunTime);

  useEffect(() => {
    if (runTime) {
      const { hh, mm } = convertToUTC(runTime);
      const days = selectedDays.map(day => parseInt(day, 10));
      const cronString = getCronString('week', [], [], days, [hh], [mm], false, {});
      setFrequency(cronString);
    }
  }, [selectedDays, runTime, setFrequency]);

  return (
    <Vertical>
      <Horizontal mt="sm">
        <Chip.Group multiple value={selectedDays} onChange={setSelectedDays}>
          <Group position="left" title="On" aria-label="On">
            {daysOfWeek.map(day => (
              <Chip key={day.value} value={day.value} variant="light" size="md" radius="sm">
                {day.label}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
      </Horizontal>
      <TimeInput
        icon={<IconClock size="1rem" stroke={1.5} />}
        label="Run at"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          setRunTime(value);
        }}
        value={runTime}
        size="sm"
        w="40%"
        required
      />
    </Vertical>
  );
};
