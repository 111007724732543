import { IconCheck, IconCopy } from '@tabler/icons-react';
import {
  ActionIcon,
  Alert,
  Box,
  CopyButton,
  Horizontal,
  Loader,
  Text,
  Tooltip,
  Vertical,
} from '../../../design-system/v2';
import { useTriggerEmailWorkflowRunQuery } from '../../../queries/workflows/list/list';

interface EmailTriggerProps {
  workflowId: string;
}
export const EmailTrigger = ({ workflowId }: EmailTriggerProps) => {
  const { data, isLoading, isError } = useTriggerEmailWorkflowRunQuery(workflowId);

  if (isLoading) {
    return <Loader size="sm" />;
  }

  if (isError || !data) {
    return (
      <Box w="100%">
        <Alert color="red" title="Error">
          Error while fetching email id. Please contact support.
        </Alert>
      </Box>
    );
  }

  return (
    <Vertical spacing="sm">
      <Box p="md" w="100%" bg="gray.0">
        <Vertical spacing="sm">
          <Text size="sm" weight={500} color="dimmed">
            Forward your emails to the email below to trigger the workflow:
          </Text>
          <Horizontal mt="sm" align="center">
            <Text align="center">{data.email}</Text>
            <CopyButton value={data.email} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Horizontal>
        </Vertical>
      </Box>
      <Text size="sm" color="gray.6" variant="small03">
        Note: Attachments should have the same schema as the one used to create the workflow.
      </Text>
    </Vertical>
  );
};
