import { ReactNode } from 'react';
import { Badge, Button, Horizontal, Skeleton, Text, Vertical } from '../../../design-system/v2';
import { AppBuilder, AppBuilderStatusKeys } from '../../../generated/api';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { AppDetailsHeader } from '../../app-store/document-ai/commons/AppDetailsHeader';
import { MoreInfoList } from '../../app-store/document-ai/commons/MoreInfoList';
import { PageInfo } from '../../app-store/document-ai/commons/PageInfo';
import { EditableText } from '../../common/EditableText';
import { SaveIndicator } from '../../common/apps/SaveIndicator';
import { useAppStatusDetails } from '../useAppStatusDetails';

const withSkeleton = (isLoading: boolean, Component: ReactNode) =>
  isLoading ? <Skeleton h={25} w={300} /> : Component;

export interface AppBuilderDetailsHeaderProps {
  appBuilderDetails: AppBuilder;
  isLoading: boolean;
  isPublishDisabled: boolean;
  isSavingChanges: boolean;
  onPublish: () => void;
  onAppNameUpdate: (name: string) => Promise<unknown>;
  onAppDescriptionUpdate: (description: string) => Promise<unknown>;
}

export const APP_BUILDER_DETAILS_HEADER_HEIGHT = 120;
const PAGE_TITLE_INPUT_MAX_WIDTH = 300;

// TODO: This component needs to be replaced with the new reusable component

export const AppBuilderDetailsHeader = ({
  appBuilderDetails,
  isLoading,
  isPublishDisabled,
  isSavingChanges,
  onPublish,
  onAppNameUpdate,
  onAppDescriptionUpdate,
}: AppBuilderDetailsHeaderProps) => {
  const { getAppBuilderRoute } = useAbsoluteRoutes();
  const appStatus = appBuilderDetails?.appStatus;
  const appStatusDetails = useAppStatusDetails(appStatus);
  const isPublishedApp = appStatus === AppBuilderStatusKeys.Published;

  // const modelList =
  //   availableModels?.map(model => ({
  //     value: model.modelId,
  //     label: model.modelId,
  //   })) ?? [];

  const MoreInfoWithSkeleton = withSkeleton(
    isLoading,
    <MoreInfoList
      infoList={[
        {
          title: 'Description',
          content: appBuilderDetails.appDescription,
          isEditable: true,
          onSave: onAppDescriptionUpdate,
        },
      ]}
    />,
  );

  const AppNameWithSkeleton = withSkeleton(
    isLoading,
    <EditableText
      maw={PAGE_TITLE_INPUT_MAX_WIDTH}
      variant="subTitle02"
      ariaLabel="App Name"
      value={appBuilderDetails?.appName ?? ''}
      onSave={onAppNameUpdate}
      required
    />,
  );

  return (
    <AppDetailsHeader cols={2}>
      <PageInfo
        parentTitle="Gen AI App Builder"
        title={AppNameWithSkeleton}
        isMetadataLoading={isLoading}
        additionalTitleElement={
          appStatusDetails ? (
            <Badge
              ml="sm"
              variant="filled"
              bg={appStatusDetails.color[0]}
              sx={{ color: appStatusDetails.color[6] }}
            >
              {appStatusDetails.title}
            </Badge>
          ) : null
        }
        backButtonRoute={getAppBuilderRoute()}
        moreInfo={MoreInfoWithSkeleton}
      />
      <Horizontal position="right">
        <Vertical>
          <Horizontal position="right" spacing="xl">
            <SaveIndicator isSaving={isSavingChanges} />
            {/* TODO: Add after ODSC demo. */}
            {/* <Select
              hideLabel
              ariaLabel="Select model"
              loading={isLoadingModels}
              options={modelList}
              value={selectedModel?.modelId}
              onChange={handleSelectedModelChange}
              withinPortal
            /> */}
            <Button
              disabled={isPublishDisabled}
              color="green"
              variant="primary"
              onClick={onPublish}
            >
              Publish app
            </Button>
          </Horizontal>
          {/* FIXME: Any changes made to the app properties 
          while testing will affect the publish app as well.
          Because we don't maintain different app properties for test and published apps.
          Once that is fixed, this message should be removed
          */}
          {isPublishedApp && (
            <Text variant="small03">
              * Changes made here will be saved to the published app also
            </Text>
          )}
        </Vertical>
      </Horizontal>
    </AppDetailsHeader>
  );
};
