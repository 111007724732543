import { Tabs } from '../../../../../design-system/v2';
import { OperatorStatusDetails, WorkflowRunOperatorStatus } from '../../../../../generated/api';
import { useGetOperatorDetailsQuery } from '../../../../../queries/workflows/operators';
import { Mode } from '../../utils';
import { OperatorDataPreviewContainer } from '../../workflow-builder/operator-parameter-form/operator-data-preview/OperatorDataPreview.container';
import { LogsViewContainer } from './LogsView.container';

enum DebugNodeTabs {
  Logs = 'Logs',
  Preview = 'Preview',
}

interface LogsModalProps {
  defaultTab: 'preview' | 'logs';
  workflowId: string;
  debugId: string;
  nodeId: string;
  status: OperatorStatusDetails;
  prevNodeId?: string;
}

export const LogsModal = ({
  workflowId,
  debugId,
  defaultTab,
  nodeId,
  status,
  prevNodeId,
}: LogsModalProps) => {
  const { data } = useGetOperatorDetailsQuery(status.operatorId);

  return (
    <Tabs
      defaultValue={defaultTab === 'logs' ? DebugNodeTabs.Logs : DebugNodeTabs.Preview}
      h="calc(90vh - 76px - 24px)"
      w="80vw"
      keepMounted={false}
    >
      <Tabs.List>
        <Tabs.Tab
          disabled={status.status === WorkflowRunOperatorStatus.Failed}
          value={DebugNodeTabs.Preview}
        >
          Preview
        </Tabs.Tab>
        <Tabs.Tab value={DebugNodeTabs.Logs}>Logs</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={DebugNodeTabs.Preview} h="calc(100% - 46px)">
        <OperatorDataPreviewContainer
          operatorId={data?.operatorId ?? ''}
          workflowId={workflowId}
          runId={debugId}
          nodeId={nodeId}
          operatorCategory={data?.category}
          prevNodeId={prevNodeId}
          mode={Mode.Debug}
        />
      </Tabs.Panel>
      <Tabs.Panel value={DebugNodeTabs.Logs} h="calc(100% - 46px)">
        <LogsViewContainer workflowId={workflowId} runId={debugId} nodeId={nodeId} />
      </Tabs.Panel>
    </Tabs>
  );
};
