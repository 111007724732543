import { DailyRun } from './DailyRun';
import { MonthlyRun } from './MonthlyRun';
import { PeriodicRun } from './PeriodicRun';
import { WeeklyRun } from './WeeklyRun';
import { TriggerType } from './util';

interface ScheduleConfigProps {
  triggerType: TriggerType;
  setFrequency: (value: string) => void;
  setInterval: (value: number) => void;
}

export const ScheduleConfig = ({
  triggerType,
  setFrequency,
  setInterval,
}: ScheduleConfigProps): JSX.Element => {
  switch (triggerType) {
    case TriggerType.DAILY:
      return <DailyRun setFrequency={setFrequency} />;
    case TriggerType.WEEKLY:
      return <WeeklyRun setFrequency={setFrequency} />;
    case TriggerType.MONTHLY:
      return <MonthlyRun setFrequency={setFrequency} />;
    case TriggerType.PERIODIC:
      return <PeriodicRun setInterval={setInterval} />;
    default:
      return <></>;
  }
};
