import noop from 'lodash/noop';
import { MarkerType, ReactFlowInstance } from 'reactflow';
import { Flow } from '../../../../design-system/v2';
import { grays } from '../../../../design-system/v2/theme/colors/colorList';
import { OperatorModel, UpsertDAGRequest } from '../../../../generated/api';
import { edgeViewerTypes } from '../../edges/factory';
import { transformEdgeToWorkflowEdge } from '../../edges/util';
import { getNodeViewerTypes } from '../../nodes/factory';
import { WorkflowEdge, transformNodeToWorkflowNode } from '../../nodes/utils';

interface WorkflowViewerProps {
  dag: UpsertDAGRequest;
  operatorsList: OperatorModel[];
  renderNodeActions?: boolean;
  isPublicTemplate?: boolean;
}

const defaultEdgeProps: Partial<WorkflowEdge> = {
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: grays.gray6,
  },
  style: {
    strokeWidth: 2,
    stroke: grays.gray6,
  },
};

export const WorkflowViewer = ({
  dag,
  operatorsList,
  renderNodeActions = false,
  isPublicTemplate = false,
}: WorkflowViewerProps): JSX.Element => {
  const nodes = dag.nodes.map(node => transformNodeToWorkflowNode(node, operatorsList));
  const edges = dag.edges.map(edge => transformEdgeToWorkflowEdge(edge));
  const nodeViewerTypes = getNodeViewerTypes(operatorsList, renderNodeActions, isPublicTemplate);

  const onInit = (reactFlowInstance: ReactFlowInstance<any, any>) => {
    if (renderNodeActions) {
      reactFlowInstance.zoomOut();
      reactFlowInstance.zoomOut();
      return;
    }

    if (nodes.length < 5) {
      reactFlowInstance.zoomTo(0.8);
      return;
    }

    reactFlowInstance.zoomTo(0.5);
  };

  return (
    <Flow.Container
      nodes={nodes}
      edges={edges}
      defaultEdgeOptions={defaultEdgeProps}
      onInit={onInit}
      onNodesChange={noop}
      onEdgesChange={noop}
      nodeTypes={nodeViewerTypes}
      edgeTypes={edgeViewerTypes}
      nodesDraggable={false}
      nodesConnectable={false}
      fitView
    >
      <Flow.Background />
    </Flow.Container>
  );
};
