import cronstrue from 'cronstrue';
import { converter } from 'react-js-cron';
import { convertFromUTC, convertTo12HourFormat } from './time-format';

/**
 * Generates a cron string based on the provided parameters using react-cron-js library.
 *
 * @param period The time period for the cron string ('year', 'month', 'week', 'day', 'hour', 'minute', 'reboot').
 * @param months The months for the cron string (number array).
 * @param monthDays The days of the month for the cron string (number array).
 * @param days The days of the week for the cron string (number array).
 * @param hours The hours for the cron string (number array).
 * @param minutes The minutes for the cron string (number array).
 * @param humanizeValue Whether to humanize the value (boolean).
 * @param dropdownConfigs Additional dropdown configurations (object).
 * @returns The generated cron string.
 */
export const getCronString = (
  period: 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'reboot',
  months: number[] | undefined,
  monthDays: number[] | undefined,
  days: number[] | undefined,
  hours: number[] | undefined,
  minutes: number[] | undefined,
  humanizeValue: boolean,
  dropdownConfigs: Record<string, any>,
): string =>
  converter.getCronStringFromValues(
    period, // period: 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'reboot'
    months, // months: number[] | undefined
    monthDays, // monthDays: number[] | undefined
    days, // weekDays: number[] | undefined
    hours, // hours: number[] | undefined
    minutes, // minutes: number[] | undefined
    humanizeValue, // humanizeValue?: boolean
    dropdownConfigs, // dropdownConfigs
  );

/**
 * Converts a cron expression into a human-readable format.
 * @param cronExpression - The cron expression to convert.
 * @returns A human-readable string representing the cron schedule.
 */
export const humaniseCron = (cronExpression: string): string => {
  try {
    return cronstrue.toString(cronExpression);
  } catch (error) {
    return 'Invalid cron expression';
  }
};

const extractTimeFromCron = (frequency: string) => {
  // Splitting the cron expression by space to extract minutes and hours
  const cronParts = frequency.split(' ');

  // Extracting minutes and hours from cronParts
  const minutes = cronParts[0]; // First part is minutes
  const hours = cronParts[1]; // Second part is hours

  // Returning hours and minutes as integers
  return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
};
/**
 * Formats a CRON expression's time component to reflect the local time in 12-hour format with AM/PM.
 *
 * This function:
 * 1. Extracts the time (hours and minutes) from the CRON expression.
 * 2. Converts the extracted time from UTC to the local time.
 * 3. Converts the local time to a 12-hour format with an AM/PM suffix.
 * 4. Replaces the time component in the humanized CRON description with the formatted local time.
 *
 * @param cronExpression - The CRON expression as a string.
 * @returns A string that describes the CRON schedule with the time in 12-hour local time format.
 *
 * @example
 * // Assuming the CRON expression is "0 13 * * *" (1:00 PM UTC)
 * // and the local time is UTC-5 (Eastern Standard Time):
 * // Returns "At 8:00 AM, every day"
 * formatCronSchedule("0 13 * * *");
 *
 * @example
 * // Assuming the CRON expression is "0 0 1 * *" (Midnight on the 1st of every month UTC)
 * // and the local time is UTC+3:
 * // Returns "At 3:00 AM, on the 1st day of the month"
 * formatCronSchedule("0 0 1 * *");
 */
export const humaniseCronString = (cronExpression: string): string => {
  const { hours, minutes } = extractTimeFromCron(cronExpression);
  const localTime = convertFromUTC(hours, minutes);
  const localTime12hrformat = convertTo12HourFormat(localTime);
  const cronDescription = humaniseCron(cronExpression);

  const updatedDescription = cronDescription.replace(
    /\b\d{1,2}:\d{2}(?:\s?[APMapm]{2})?\b/,
    localTime12hrformat,
  );
  return updatedDescription;
};
