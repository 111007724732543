import first from 'lodash/first';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Card, Center, Loader, Text } from '../../../../design-system/v2';
import { DEFAULT_PAGE_SIZE } from '../../../../design-system/v2/core/data-display/table/util';
import { useGetWorkflowRunsQuery } from '../../../../queries/workflows/list/list';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { WorkflowViewerContainer } from '../viewer/WorkflowViewer.container';
import { WorkflowRunsListContainer } from './WorkflowRunsList.container';
import { PANEL_LEFT_MARGIN, PANEL_TOP_MARGIN, PANEL_WIDTH, runTabFilter } from './util';

interface WorkflowRunsWrapperProps {
  workflowId?: string;
}

export const WorkflowRunsWrapper = ({ workflowId: wfId = '' }: WorkflowRunsWrapperProps) => {
  const { workflowId = wfId } = useParams();
  const navigate = useNavigate();
  const { getWorkflowRunDetailsRoute } = useAbsoluteRoutes();
  const { data, isError, isLoading, isFetching } = useGetWorkflowRunsQuery({
    workflowId: workflowId,
    start: 0,
    limit: DEFAULT_PAGE_SIZE,
    sortKey: 'startDate',
    filters: [runTabFilter],
  });

  const runId = first(data?.response)?.workflowRunId;

  useEffect(() => {
    if (runId && runId.length > 0) {
      navigate(getWorkflowRunDetailsRoute(workflowId, runId));
    }
  }, [runId]);

  if (isLoading || isFetching) {
    return (
      <Center h="100%">
        <Loader text="Loading workflow runs" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert color="red">There was an error loading your workflow runs. Please try again</Alert>
    );
  }

  return (
    <Box h="100%" w="100%" pos="relative">
      <Card
        w={PANEL_WIDTH}
        h={`calc(100% - ${PANEL_TOP_MARGIN * 2}px)`}
        pos="absolute"
        left={PANEL_LEFT_MARGIN}
        top={PANEL_TOP_MARGIN}
        bottom={PANEL_TOP_MARGIN}
        radius="sm"
        sx={{ zIndex: 2, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
      >
        <WorkflowRunsListContainer workflowId={workflowId} />
      </Card>
      <Box h="100%" w="100%">
        {data.response.length === 0 ? (
          <Center h="100%" w="100%">
            <Text>There are no runs that have run for this workflow.</Text>
          </Center>
        ) : (
          <WorkflowViewerContainer workflowId={workflowId} />
        )}
      </Box>
    </Box>
  );
};
