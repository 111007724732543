import { useLocalStorage } from '@mantine/hooks';
import { v4 as uuid } from 'uuid';
import {
  AnswerTypes,
  DataResourceType,
  MKVLibraryStorageResourceModel,
  PostgresStorageResourceModel,
  QuestionTypes,
  SnowflakeStorageResourceModel,
  StorageType,
} from '../../generated/api';
import { StorageMetadataModel } from '../data-sources/cell-renderers/config';

export const supportedConnectorTypes = [
  StorageType.Postgresql,
  StorageType.Snowflake,
  StorageType.MarkovLibrary,
];

export const supportedResourceTypes = [
  DataResourceType.Csv,
  DataResourceType.Table,
  DataResourceType.Database,
  DataResourceType.Default,
];

export enum SuggestionType {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN',
}

export interface ChatItem {
  id: string;
  question: string;
  questionType: QuestionTypes;
  answer: string;
  answerType: AnswerTypes;
  isLoading: boolean;
  timestamp: number;
}

export interface Thread {
  id: string;
  content: ChatItem[];
}

const KEY_PREFIX = '__markov__';

const defaultResourceThreadId: Record<string, string> = {};

export const getThreadsStorageKey = (resourceId: string) =>
  `${KEY_PREFIX}resource_thread_history_${resourceId}`;

export const useDataSourceThreadsStorage = (resourceId: string) => {
  if (!defaultResourceThreadId[resourceId]) {
    defaultResourceThreadId[resourceId] = uuid();
  }

  return useLocalStorage<Thread[]>({
    key: getThreadsStorageKey(resourceId),
    defaultValue: [
      {
        id: defaultResourceThreadId[resourceId],
        content: [],
      },
    ],
    getInitialValueInEffect: false,
  });
};

export const DATA_ANALYTICS_NAME = 'Data Analytics';

interface LeafResourceSchemaTable {
  schema: string;
  table: string;
}

interface DataAnalyticsStorageConfig {
  getLeafResourceSchemaAndTable: (metadata: StorageMetadataModel) => LeafResourceSchemaTable;
}

export const dataAnalyticsStorageConfigMap: Partial<
  Record<StorageType, DataAnalyticsStorageConfig>
> = {
  [StorageType.Snowflake]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { snowflakeSchema = '' } = metadata as SnowflakeStorageResourceModel;
      const { tableName = '' } = metadata as SnowflakeStorageResourceModel;
      return { schema: snowflakeSchema, table: tableName };
    },
  },
  [StorageType.Postgresql]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { tableName = '' } = metadata as PostgresStorageResourceModel;
      return { schema: 'Public', table: tableName };
    },
  },
  [StorageType.MarkovLibrary]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { filePath } = metadata as MKVLibraryStorageResourceModel;
      return { schema: '', table: filePath };
    },
  },
};
